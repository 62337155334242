/**
 * @generated SignedSource<<a9188c91fed1f44b970beb23a6fe5035>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SidebarContactAdminsCarouselItemQuery$variables = {
  userId: string;
  organizationId: string;
};
export type SidebarContactAdminsCarouselItemQuery$data = {
  readonly user: {
    readonly id?: string;
    readonly fullName?: string;
    readonly organizationMembership?: {
      readonly id: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment">;
  } | null;
};
export type SidebarContactAdminsCarouselItemQuery = {
  variables: SidebarContactAdminsCarouselItemQuery$variables;
  response: SidebarContactAdminsCarouselItemQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "organizationId",
      "variableName": "organizationId"
    }
  ],
  "concreteType": "OrganizationMembership",
  "kind": "LinkedField",
  "name": "organizationMembership",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SidebarContactAdminsCarouselItemQuery",
    "selections": [
      {
        "alias": "user",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProfileAvatarFragment"
              },
              (v5/*: any*/)
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SidebarContactAdminsCarouselItemQuery",
    "selections": [
      {
        "alias": "user",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatar",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isTest",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "06ebaebf96a6fd3a2f872de945d5008b",
    "id": null,
    "metadata": {},
    "name": "SidebarContactAdminsCarouselItemQuery",
    "operationKind": "query",
    "text": "query SidebarContactAdminsCarouselItemQuery(\n  $userId: ID!\n  $organizationId: ID!\n) {\n  user: node(id: $userId) {\n    __typename\n    ... on User {\n      id\n      fullName\n      ...ProfileAvatarFragment\n      organizationMembership(organizationId: $organizationId) {\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n"
  }
};
})();

(node as any).hash = "13e4e1b5c5e50a5d758e22f6eeb829b9";

export default node;
